<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="districts"
          :total-pages.sync="totalPages"
          :filters="filters"
          :is-loading="isLoadingData"
          title="Kecamatan"
          subtitle="Halaman untuk menambahkan kecamatan"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @province-filtered="handleProvinceFilter"
          @regency-filtered="handleRegencyFilter"
          @change-page="paginationHandler"
          @reset-filter="resetFilter"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kecamatan': 'Edit Kecamatan'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="district.province_uuid"
            :items="provinces"
            label="Provinsi"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            return-object
            clearable
            :disabled="dialog =='edit'"
            @change="changeListRegencies()"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="dialog =='add'"
            v-model="district.regencies_uuid"
            :items="regenciesByProvince"
            label="Kabupaten"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            open-on-clear
            :disabled="!district.province_uuid"
          >
          </v-autocomplete>
          <v-autocomplete
            v-if="dialog =='edit'"
            v-model="district.regencies_uuid"
            :items="regencies"
            label="Kabupaten"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            open-on-clear
            :disabled="!district.province_uuid"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="district.name"
            label="Kecamatan"
            :disabled="!district.regencies_uuid"
            dense
            outlined
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :width="500"
      :is-loading-button="isLoadingButton"
      header="Hapus Kecamatan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'
import SkeletonLoaderTable from '../components/SkeletonLoaderTable.vue'
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiMagnify, mdiPencilOutline, mdiPlus } from '@mdi/js'

export default {
  name: 'District',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      isDisabled: true,
      search: '',
      dialog: null,
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      confirmDeleteDialog: false,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 10,
      service: 'district',
      provinces: [],
      province_uuid: '',
      regencies: [],
      regencies_uuid: [],
      regenciesByProvince: [],
      isNullRegencies: '',
      errorMessagesRegencies: '',
      districts: [],
      district: {
        name: '',
        regencies_uuid: {},
        province_uuid: {},
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Provinsi', value: 'province' },
        { text: 'Kabupaten/Kota', value: 'regencies' },
        { text: 'Kecamatan', value: 'name' },
        { text: 'Aksi', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
      filters: [],
      filterQuery: {
        province: '',
        regencies: '',
        district: '',
      },
      formValid: false,
    }
  },
  watch: {
    district: {
      handler() {
        const valid = []
        const requiredField = ['name', 'regencies_uuid']
        Object.entries(this.district).forEach(([key, item]) => {
          if (requiredField.includes(key)) {
            if (requiredField.includes(key) && Object.keys(item).length !== 0) {
              valid.push(!!(item && Object.keys(item).length))
            } else {
              valid.push(!!(item && item.length))
            }
          }
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listDistrict({ ...this.filterQuery })
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listProvince()
    await this.listRegency()
    await this.listDistrict()
    this.isLoadingTable = false
    this.insertFiltersOption()
  },
  methods: {
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    async listRegency() {
      await this.$services.ApiServices.list('regency', { per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },
    async listDistrict(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.totalItems = data.meta.total
          this.districts = data.data.map((districts, index) => ({
            ...districts,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    resetForm() {
      this.district.name = ''
      this.district.province_uuid = ''
      this.district.regencies_uuid = ''
      this.regenciesByProvince = []
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    changeListRegencies() {
      this.isNullRegencies = ''
      if (this.district.province_uuid) {
        this.isDisabled = false
      }
      if (this.district !== null) {
        this.regenciesByProvince = this.regencies.filter(
          item => item.province_uuid === this.district.province_uuid.uuid,
        )
      }
      if (this.regenciesByProvince.length < 1) {
        this.isNullRegencies = 'provinsi yang anda pilih belum memiliki kabupaten'
      }
    },
    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.add(this.service, {
        name: this.district.name,
        regencies_uuid: this.district.regencies_uuid.uuid,
      }).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listDistrict()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.district = data.data
          this.district.uuid = data.data.uuid
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      this.isDisabledButton = true

      await this.$services.ApiServices.update(this.service, this.district, this.district.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listDistrict()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.district.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy(this.service, this.district.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listDistrict()
    },
    changeListDistrict() {},
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    insertFiltersOption() {
      const provinceFilters = []
      const regencyFilters = []
      this.provinces.forEach(item => {
        provinceFilters.push(item.name)
      })
      this.filters = [
        ...this.filters,
        {
          label: 'Province',
          emitName: 'province-filtered',
          data: provinceFilters,
        },
        {
          label: 'Regency',
          emitName: 'regency-filtered',
          data: regencyFilters,
        },
      ]
    },
    async handleProvinceFilter(event) {
      this.filterQuery.province = event
      this.filterQuery.regencies = ''
      const regencyFilters = []
      await this.$services.ApiServices.list('regency', { province: this.filterQuery.province }).then(
        ({ data }) => {
          data.data.forEach(item => {
            regencyFilters.push(item.name)
          })
          this.filters = this.filters.filter(item => item.label !== 'Regency')
          this.filters = [
            ...this.filters,
            {
              label: 'Regency',
              emitName: 'regency-filtered',
              data: regencyFilters,
            },
          ]
        },
        err => console.error(err),
      )
      await this.listDistrict()
    },
    async handleRegencyFilter(event) {
      this.filterQuery.regencies = event
      this.isLoadingData = true
      await this.listDistrict()
      this.isLoadingData = false
    },
    resetFilter() {
      this.filterQuery = {
        province: '',
        regency: '',
        district: '',
      }

      this.listDistrict()
    },
    searchHandler(district) {
      this.filterQuery.district = district
      this.page = 1
      this.listDistrict({ ...this.filterQuery })
    },
  },
}
</script>

<style>
</style>
